"use strict";

var QQForm = React.createClass({
  displayName: "QQForm",

  getInitialState: function getInitialState() {
    return {
      value: "atLeast"
    };
  },
  update: function update(e) {
    e.preventDefault();
    var theVal = {
      selector: React.findDOMNode(this.refs.selectorName).value,
      type: this.state.value,
      amount: {
        one: React.findDOMNode(this.refs.amountOne).value,
        two: React.findDOMNode(this.refs.amountTwo).value
      }
    };
    if (!theVal.selector || !theVal.amount.one) {
      return;
    }
    this.props.onUpdate(theVal);
  },
  displayAmount: function displayAmount(e) {
    this.setState({ value: e.target.value });
  },
  render: function render() {
    var isBetween = undefined;
    if (this.state.value == "between") {
      isBetween = true;
    } else {
      isBetween = false;
    }
    return React.createElement(
      "section",
      { className: "formControl" },
      React.createElement(
        "h2",
        null,
        "Monte uma Quantity Query"
      ),
      React.createElement(
        "form",
        { onSubmit: this.update },
        React.createElement(
          "div",
          { className: "inputRow" },
          React.createElement(
            "label",
            { htmlFor: "element", className: "inputLabel" },
            "Qual elemento será contado",
            " ",
            React.createElement(
              "span",
              {
                className: "hint hint--bottom",
                "data-hint": "Os elementos que serão selecionados na query"
              },
              "?"
            )
          ),
          React.createElement("input", {
            type: "text",
            id: "element",
            autoFocus: true,
            ref: "selectorName",
            placeholder: "ex. ul li",
            required: true
          })
        ),
        React.createElement(
          "div",
          { className: "inputRow" },
          React.createElement(
            "label",
            { htmlFor: "type" },
            "Tipo",
            " ",
            React.createElement(
              "span",
              {
                className: "hint hint--bottom hint--bounce",
                "data-hint": "Que tipo de query será usada para contar os elementos"
              },
              "?"
            )
          ),
          React.createElement(
            "select",
            {
              id: "type",
              ref: "type",
              onChange: this.displayAmount,
              value: this.state.value
            },
            React.createElement(
              "option",
              { value: "atLeast" },
              "Pelo Menos"
            ),
            React.createElement(
              "option",
              { value: "atMost" },
              "No Máximo"
            ),
            React.createElement(
              "option",
              { value: "between" },
              "Entre"
            )
          )
        ),
        React.createElement(
          "div",
          { className: "inputRow" },
          React.createElement(
            "label",
            { "for": "amount", className: "inputLabel" },
            "Quantidade de Itens",
            " ",
            React.createElement(
              "span",
              {
                className: "hint hint--bottom hint--bounce",
                "data-hint": "Itens para contar"
              },
              "?"
            )
          ),
          React.createElement("input", {
            type: "number",
            ref: "amountOne",
            required: true,
            placeholder: isBetween ? "Pelo menos # de itens para contar" : "# de itens para contar"
          }),
          React.createElement("input", {
            type: isBetween ? "number" : "hidden",
            ref: "amountTwo",
            required: true,
            placeholder: "No máximo # de itens para contar"
          })
        ),
        React.createElement("input", {
          type: "submit",
          className: "submit",
          value: "Criar Quantity Query"
        })
      )
    );
  }
});

var QQExample = React.createClass({
  displayName: "QQExample",

  getInitialState: function getInitialState() {
    return {
      items: ["i", "i", "i", "i", "i"]
    };
  },
  addItem: function addItem() {
    var newArray = this.state.items;
    newArray.push("i");
    this.setState({ arr: newArray });
    console.log(this.state.items);
  },
  removeItem: function removeItem() {
    if (this.state.items.length > 1) {
      var newArray = this.state.items;
      newArray.pop();
      this.setState({ arr: newArray });
    } else {
      return;
    }
  },
  render: function render() {
    var itemObjects = this.state.items.map(function (item) {
      return React.createElement("li", { className: "item" });
    });
    return React.createElement(
      "div",
      null,
      React.createElement(
        "style",
        null,
        this.props.styles
      ),
      React.createElement(
        "p",
        null,
        React.createElement("br", null),
        "Sua quantity query será refletida nos itens abaixo por uma mudança de cor."
      ),
      React.createElement(
        "header",
        { className: "controls" },
        React.createElement(
          "div",
          { onClick: this.addItem, className: "itemClick" },
          React.createElement("i", { className: "fa fa-plus-circle " }),
          " Adicionar Item"
        ),
        React.createElement(
          "div",
          { onClick: this.removeItem, className: "itemClick" },
          React.createElement("i", { className: "fa fa-minus-circle" }),
          " Remover Item"
        )
      ),
      React.createElement(
        "section",
        { className: "itemList" },
        React.createElement(
          "ul",
          null,
          itemObjects
        )
      )
    );
  }
});

var QQDisplay = React.createClass({
  displayName: "QQDisplay",

  render: function render() {
    var type = this.props.data.type,
        amountOne = this.props.data.amount.one,
        amountTwo = this.props.data.amount.two,
        selector = this.props.data.selector,
        selectTail = selector.split(" ").splice(-1)[0];
    var pseudo,
        equation = "// Seu código vai aparecer aqui",
        styles;
    if (type === "atLeast") {
      equation = selector + ":nth-last-child(n+" + amountOne + "), " + selector + ":nth-last-child(n+" + amountOne + ") ~ " + selectTail + " { }";
      styles = "section.itemList ul>li:nth-last-child(n+" + amountOne + "), section.itemList ul>li:nth-last-child(n+" + amountOne + ") ~ li { background: #D81B60 !important; }";
    } else if (type === "atMost") {
      equation = selector + ":nth-last-child(-n+" + amountOne + "):first-child, " + selector + ":nth-last-child(-n+" + amountOne + "):first-child ~ " + selectTail + " { }";
      styles = "section.itemList ul>li:nth-last-child(-n+" + amountOne + "):first-child, section.itemList ul>li:nth-last-child(-n+" + amountOne + "):first-child ~ li { background: #D81B60 !important;  }";
    } else if (type === "between") {
      equation = selector + ":nth-last-child(n+" + amountOne + "):nth-last-child(-n+" + amountTwo + "):first-child, " + selector + ":nth-last-child(n+" + amountOne + "):nth-last-child(-n+" + amountTwo + "):first-child ~ " + selectTail + " { }";
      styles = "section.itemList ul li:nth-last-child(n+" + amountOne + "):nth-last-child(-n+" + amountTwo + "):first-child, section.itemList ul li:nth-last-child(n+" + amountOne + "):nth-last-child(-n+" + amountTwo + "):first-child ~ li { background: #D81B60 !important; }";
    }
    return React.createElement(
      "div",
      { className: "displayBody" },
      React.createElement(
        "section",
        { className: "equationDisplay" },
        React.createElement(
          "h2",
          null,
          "Código"
        ),
        React.createElement(
          "p",
          null,
          "Copie e cole o código abaixo em seus estilos"
        ),
        React.createElement(
          "code",
          null,
          equation
        )
      ),
      React.createElement(QQExample, { styles: styles })
    );
  }
});

var QQApp = React.createClass({
  displayName: "QQApp",

  getInitialState: function getInitialState() {
    return {
      data: {
        selector: "",
        amount: {
          one: "",
          two: ""
        },
        type: ""
      }
    };
  },
  onUpdate: function onUpdate(val) {
    // console.log(val)
    this.setState({
      data: {
        selector: val.selector,
        amount: {
          one: val.amount.one,
          two: val.amount.two
        },
        type: val.type
      }
    });
  },
  render: function render() {
    return React.createElement(
      "section",
      { className: "appBody" },
      React.createElement(QQForm, { onUpdate: this.onUpdate, data: this.state.data }),
      React.createElement(QQDisplay, { data: this.state.data })
    );
  }
});

$(function () {
  console.log("What's it built with? React, Babel and PostCSS! Help me improve or fix any issues! https://github.com/drewminns/qqui");
  console.log("Follow me on Twitter: @drewisthe");
  console.log("Find more crazy cool things at drewminns.com");
  React.render(React.createElement(QQApp, null), document.getElementById("content"));
  $("p.explain").on("click", function () {
    $("#lightbox").addClass("show");
  });
  $(".closeButton").on("click", function () {
    $("#lightbox").removeClass("show");
  });
  $(document).keyup(function (e) {
    if (e.keyCode == 27) {
      // escape key maps to keycode `27`
      $("#lightbox").removeClass("show");
    }
  });
});